import { FunctionComponent } from "react";

export const EducationSlot: FunctionComponent = () => {
  return (
    <div>
      <h5 className="my-3 text-2xl font-bold">Expirience</h5>
      <div>
        <h5>Polsl</h5>
        <p>Polsl inz</p>
        <p></p>
      </div>
    </div>
  );
};
